'use client';

import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import FdocList from '@/src/components/FdocList/FdocList';
import {
  DashboardContent,
  DashboardContentSection,
} from '@/src/core/shared/Dashboard/DashboardContent';
import { DashboardPageWrapper } from '@/src/core/shared/Dashboard/DashboardPageWrapper';
import { useAuthUser } from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import SearchIcon from '@/src/icons/SearchIcon';
import MigrationBanner from '@/src/modules/Migration/components/MigrationBanner';
import {
  FeatureFlag,
  useIsFeatureFlagEnabled,
} from '@/src/modules/feature-flags/feature-flags.store';
import { GeneratedHomeList } from '@/src/modules/generated/components/GeneratedHomeList';
import { HomeSection, HomeSectionTitle } from '@/src/modules/home/components/HomeComponents';
import { HomeIntegrationsSection } from '@/src/modules/home/components/IntegrationsSection';
import { HomeRecentSpacesSection } from '@/src/modules/home/components/RecentSpacesSection';
import GetStartedChecklist from '@/src/modules/onboarding/components/GetStartedChecklist/GetStartedChecklist';
import { useIsChecklistHidden } from '@/src/modules/onboarding/hooks/useChecklistHidden';
import { ModalNewResourceSelector } from '@/src/modules/resources/components/ModalNewResourceSelector';
import { useQueryResourceList } from '@/src/modules/resources/queries/useQueryResourceList';
import { resourceKindsExceptFolder } from '@/src/modules/resources/resources.config';
import { Flex } from '@/src/modules/ui/components/Flex';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { H1 } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import useFdocSwitcher from '@/src/store/fdocSwitcher';
import useUIStore, { useSearchOverlay } from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import { LayoutGroup, Variants, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

const QuestTooltip = dynamic(
  () => import('@/src/modules/onboarding/components/OnboardingTooltip'),
  {
    ssr: false,
  },
);

const SearchbarTriggerButton = styled.button`
  position: relative;
  z-index: 2;
  display: flex;
  padding: 12px 12px 12px 0;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  width: 100%;
  height: 46.7px;

  color: ${cssVar['color-text-placeholder']};
  svg {
    color: ${cssVar['color-text-primary']};
  }
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 140% */
`;

const SearchbarTriggerButtonBackground = styled(motion.div)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: 1px solid ${cssVar['color-border-primary']};
  background: ${cssVar['color-bg-primary']};
  border-radius: 14px;
`;

const SectionsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

const customTimelineStyleFunction = (fdoc: Fdoc) => {
  if (fdoc.type === 'folder') {
    return {
      // revert the scaling of the folder icon
      transform: 'scale(1.4)',
      maxWidth: 168,
    };
  }
  return { width: 168, height: 192 };
};

const DashboardContentSectionWithFdocsWrapper = styled.div`
  // prevent layout shift
  min-height: 216px;

  // flex center so the loader is centered
  display: flex;
  align-items: center;
  justify-content: center;

  margin: -10px;
`;

/**
 * this file will need some more trimming in terms of the list of recent resources
 * waiting for refactor
 */
const Home: React.FC = () => {
  const user = useAuthUser();
  const { sidebarExpanded } = useUIStore(
    (state) => ({
      sidebarExpanded: state.sidebarExpanded,
    }),
    shallow,
  );
  const isChecklistHidden = useIsChecklistHidden();

  const { isOverlayOpen: isSearchOverlayOpen, openOverlay: openSearchOverlay } = useSearchOverlay();

  const {
    fdocs: resources,
    isLoading: fdocsLoading,
    fetchNextPage,
    hasNextPage,
  } = useQueryResourceList(
    {
      kind: resourceKindsExceptFolder,
      order: {
        property: 'modifiedAt',
        direction: 'DESC',
      },
    },
    {
      enabled: !isSearchOverlayOpen,
    },
  );

  const showExpandedFdoc = useShowExpandedFdoc();
  const fdocClickHandler = useCallback(
    async (fdoc?: Fdoc) => {
      if (!fdoc) return;

      showExpandedFdoc(fdoc.id);
    },
    [showExpandedFdoc],
  );

  const { isMobileView } = useResponsive();

  const setSwitcherFdocIds = useFdocSwitcher((s) => s.setSwitcherFdocIds, shallow);

  useEffect(() => {
    if (isSearchOverlayOpen) return;

    setSwitcherFdocIds(resources.map((n) => n.id));
  }, [resources, setSwitcherFdocIds, isSearchOverlayOpen]);

  const variants: Variants = {
    overlay: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0,
      },
    },
    normal: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.3,
      },
    },
  };

  const isGeneratedSpacesEnabled = useIsFeatureFlagEnabled(FeatureFlag.GENERATED_SPACES);

  return (
    <DashboardPageWrapper>
      <DashboardContent
        background="secondary"
        innerProps={{
          disableSidePadding: true,
          gap: 'sectionsMedium',
        }}
      >
        <DashboardContentSection
          as={motion.div}
          key="header"
          animate={isSearchOverlayOpen && !isMobileView ? 'overlay' : 'normal'}
          variants={variants}
          style={{ marginBottom: 10 }}
        >
          <Flex
            gap="sections"
            justifyContent="space-between"
            style={{
              paddingTop: 'env(safe-area-inset-top)',
            }}
          >
            <Flex gap="sectionsShort" direction="column">
              {isMobileView && (
                <LinkTouch href="/settings/account">
                  <AvatarUser user={user} size={30} tooltip={undefined} />
                </LinkTouch>
              )}
              <H1
                style={{ fontSize: '2rem', fontFamily: 'Manrope, sans-serif', fontWeight: '800' }}
              >
                Welcome, {user?.name}
              </H1>
            </Flex>

            {!isMobileView && <ModalNewResourceSelector />}
          </Flex>
        </DashboardContentSection>
        {!isMobileView && !sidebarExpanded ? (
          !isSearchOverlayOpen ? (
            <DashboardContentSection key="search" style={{ marginBottom: 30 }}>
              <SearchbarTriggerButton onClick={() => openSearchOverlay('global')}>
                <LayoutGroup id="search-bar">
                  <SearchbarTriggerButtonBackground layout={true} layoutId="search-bar-bg" />
                  <motion.div layoutId="search-bar-icon" layout="position">
                    <SearchIcon
                      style={{
                        width: 26,
                        height: 18,
                        marginLeft: 12,
                        marginRight: -4,
                      }}
                    />
                  </motion.div>
                  <motion.span layout="position" layoutId="search-bar-input">
                    What are you looking for?
                  </motion.span>
                </LayoutGroup>
              </SearchbarTriggerButton>
            </DashboardContentSection>
          ) : (
            <div
              style={{
                width: '100%',
                height: sidebarExpanded ? 0 : 46.7,
                marginBottom: sidebarExpanded ? 0 : 30,
                display: isMobileView ? 'none' : undefined,
                transition: 'height 0.2s',
              }}
            />
          )
        ) : null}

        {!isChecklistHidden && <GetStartedChecklist />}

        <QuestTooltip />

        <SectionsContainer
          key="sections"
          animate={isSearchOverlayOpen && !isMobileView ? 'overlay' : 'normal'}
          variants={variants}
        >
          {isGeneratedSpacesEnabled && <GeneratedHomeList />}

          <HomeSection>
            <DashboardContentSection>
              <HomeSectionTitle as={LinkTouch} href="/timeline">
                Recent items <ChevronRightThinIcon style={{ height: 12 }} />
              </HomeSectionTitle>
            </DashboardContentSection>
            <DashboardContentSectionWithFdocsWrapper>
              <ScrollArea
                orientation="horizontal"
                scrollbarVariant="none"
                style={{ minWidth: '100%' }}
              >
                <DashboardContentSection as={Flex}>
                  <FdocList
                    showLoadingSkeletons={hasNextPage}
                    layoutId="home"
                    canMultiSelect={false}
                    viewMode="Grid"
                    fdocs={resources}
                    padding={'12px 10px'}
                    onInfiniteTrigger={fetchNextPage}
                    isRecentItemsList={true}
                    gap="16px"
                    fdocClickHandler={fdocClickHandler}
                    skipAnimation
                    alwaysShowFirstDivision={false}
                    emptyStateImage={null}
                    emptyStateTitle="Nothing here yet"
                    emptyListMessage=""
                    nonVerticallyCenteredEmptyState
                    zoomLevel={1}
                    hideScrollbars
                    canDelete={true}
                    showSkeleton={fdocsLoading || true}
                    customStyleFunction={customTimelineStyleFunction}
                    customItemPropsFunction={() => ({ noBoxShadow: true })}
                    mainListStyle={{
                      overflow: 'none',
                    }}
                    subListStyles={() => ({
                      gridAutoFlow: 'column',
                      gridTemplateColumns: `repeat(auto-fill, 168px)`,
                      gridAutoRows: '192px',
                      gap: 12,
                      minWidth: 'max-content',
                    })}
                    subListRootStyles={() => ({
                      minWidth: 'fit-content',
                    })}
                  />
                </DashboardContentSection>
              </ScrollArea>
            </DashboardContentSectionWithFdocsWrapper>
          </HomeSection>

          <HomeRecentSpacesSection />
          <HomeIntegrationsSection />
        </SectionsContainer>

        <DashboardContentSection>
          <MigrationBanner className="mt-[30px]" />
        </DashboardContentSection>
      </DashboardContent>
    </DashboardPageWrapper>
  );
};

export default memo(Home);
